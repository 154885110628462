import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import parse from "html-react-parser";
import { INITIAL_EVENTS_FULL, INITIAL_EVENTS_PART, setTodayStr, addDays, reduceDays, formatDate } from "./event-utils";
import { cohorts, defaultDate } from "./cohortDates";
import "./index.css";
import { useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.event.title}</b>
        </>
    );
}

function getUrl(url) {
    if (url.includes('b0481b27')) {
        return url.replace('b0481b27', 'docs');
    } else if (url.includes('5d1ed98e')) {
        return url.replace('5d1ed98e', 'docs');
    } else if (url.includes('7318b848')) {
        return url.replace('7318b848', 'docs');
    } else if (url.includes('d395503c')) {
        return url.replace('d395503c', 'docs');
    } else {
        return url;
    }
}

function DemoApp({ isDemo }) {
    const [showReset, setShowReset] = useState(false)
    const [eventObj, setEventObj] = useState({});
    const [partTime, setTime] = useState(true);
    const [isDateSet, setDate] = useState(false);
    const [isLate, setLate] = useState(localStorage.getItem("calendar-late") == "true");
    const { cohortCode } = useParams();


    useEffect(() => {
        setTodayStr(cohortCode)
        setDate(true)
        let isModifiedCalendar = !!localStorage.getItem('part-web') || !!localStorage.getItem('full-web')
        setShowReset(isModifiedCalendar)
    }, [])

    if (!isDateSet) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    function handleResetClick() {
        localStorage.removeItem("part-web")
        localStorage.removeItem("full-web");
        localStorage.removeItem("calendar-late");
        setLate(false)
        setShowReset(false);
    }

    function getFinalEventData(id) {
        let partEvents = JSON.parse(localStorage.getItem('part-web')) || INITIAL_EVENTS_PART
        let fullEvents = JSON.parse(localStorage.getItem('full-web')) || INITIAL_EVENTS_FULL

        if (id) {
            return partTime ? partEvents.find((e) => e.id == id ) : fullEvents.find((e) => e.id == id );
        }
        else {
            return partTime ? partEvents : fullEvents;
        }
       
    }


    function renderSidebar() {
        return (
            <div className="demo-app-sidebar">
                {
                    <div className="schedule">
                        <button
                            onClick={() => setTime(true)}
                            className={`scheduleButton ${partTime ? "btnActive" : ""}`}
                        >
                            Part Time
                        </button>
                        <button
                            onClick={() => setTime(false)}
                            className={`scheduleButton ${partTime ? "" : "btnActive"}`}
                        >
                            Full Time
                        </button>
                        <p>
                            You're viewing the <b>{partTime ? "Part-Time" : "Full-Time"}</b> Learning
                            Schedule
                        </p>
                        <i>
                            *Takes approximately <b>{partTime ? "28 weeks" : "12 weeks"}</b> to
                            finish
                        </i>
                        {/* {
                            !isDemo && (
                            <FormGroup>
                                <FormControlLabel className="lazyText" control={<Checkbox onClick={handleLateJoiners} checked={isLate} />} label="I'm starting late. Give me 7 extra days." />
                            </FormGroup>    
                            )
                        } */}
                    </div>
                }
                <div className="demo-app-sidebar-section">
                    {eventObj.extendedProps ? (
                        <>
                            <h3>{eventObj.title}</h3>
                            <p>{eventObj.extendedProps.desc}</p>
                            {eventObj.extendedProps?.guides?.length ? (
                                <ul>
                                    {eventObj.extendedProps?.guides.map((g) => {
                                        return parse(g);
                                    })}
                                </ul>
                            ) : (
                                ""
                            )}
                            <a href={getUrl(eventObj.extendedProps.url)} target="_blank">
                                <button className="moduleBtn">
                                    {eventObj.classNames.includes('graded') ? "View Assignment":"View Lesson"}
                                </button>
                            </a>
                        </>
                    ) : (
                        <div>
                            <p>Please click on an event to view details</p>
                            <div className="previewContainer">
                                <span className="preview normal"></span> = Lesson
                            </div>
                            <div className="previewContainer">
                                <span className="preview graded"></span> =  Mandatory Assignments ⭐️
                            </div>
                            <div className="previewContainer">
                                <span className="preview mentor"></span> = Live Mentor calls 🤙
                            </div>
                            <div className="previewContainer">
                                <span className="preview done"></span> = Mandatory Tasks
                            </div>
                            <div className="previewContainer">
                                <span className="preview optional"></span> = Optional
                            </div>
                            <p>You are free to adjust the schedule as per your own comfort</p>
                            {
                                showReset && (
                                    <button
                                        onClick={handleResetClick}
                                        className={`scheduleButton ${partTime ? "btnActive" : ""} reset`}
                                    >
                                        Reset Calendar
                                    </button>
                                )
                            }
                        </div>
                    )}
                </div>

            </div>
        );
    }

    function handleDateSelect() {
        setEventObj({});

    }

    function handleEventClick(clickInfo) {
        setEventObj(getFinalEventData(clickInfo.event.id));
    };


    async function handleLateJoiners() {
        let cohortNumber = window.location.pathname.split('/')[1]
        if (!cohortNumber) {
            return;
        }
        setShowReset(true)
        setLate(!isLate)
        localStorage.setItem("calendar-late", !isLate);
        let chooseData = getFinalEventData()
        let eventsClone = JSON.parse(JSON.stringify(chooseData))
        let newEvents = eventsClone.map((e) => {
            if (!isLate) {
                e.start = addDays(new Date(e.start), 7)
                if (e.end) {
                    e.end = addDays(new Date(e.end), 7)
                }
            }
            else {
                e.start = reduceDays(new Date(e.start), 7)
                if (e.end) {
                    e.end = reduceDays(new Date(e.end), 7)
                }
            }

            return e
        })
        localStorage.setItem(partTime ? 'part-web' : 'full-web', JSON.stringify(newEvents))
    }

    function handleDrop({ event }) {
        let chooseData = getFinalEventData()
        let eventsClone = JSON.parse(JSON.stringify(chooseData))
        let newEvents = eventsClone.map((e) => {
            if (e.id == event.id) {
                e.start = addDays(event.start, 1)
            }
            return e
        })
        localStorage.setItem(partTime ? 'part-web' : 'full-web', JSON.stringify(newEvents))
        setShowReset(true)
    }

    const eventData = getFinalEventData()

    let cohortNumber = window.location.pathname.split('/')[1]
    const startDate = cohorts[cohortNumber]?.date || defaultDate


    const totalProgress = eventData.reduce((acc, obj) => {
        if (obj.isComplete) {
            return acc + 1
        }
        else {
            return acc
        }
    }, 0)

    return (
        <>

            <div className="previewHeader">
                This is your personal calendar. Edits are saved in the browser itself. Drag and adjust lessons as per your comfort.
            </div>
            <div className="demo-app">
                {renderSidebar()}
                <div className="demo-app-main">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth",
                        }}
                        // validRange={{
                        //     start: startDate,
                        //     end: isLate ? addDays(startDate, 173) : addDays(startDate, 166)
                        //   }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={eventData}
                        select={handleDateSelect}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        eventDrop={handleDrop}
                    />
                </div>
            </div>
        </>
    );
}

export default DemoApp;
