import {
  cohorts,
  defaultDate
} from './cohortDates'


let cohortNumber = window.location.pathname.split('/')[1]
let todayStr = cohorts[cohortNumber]?.date || defaultDate

export function setTodayStr(cohortNum) {
  todayStr = cohorts[cohortNum]?.date || defaultDate
}


let eventGuid = 0

function createEventId() {
  return String(eventGuid++)
}

export function formatDate(d) {
  return d.toISOString().replace(/T.*$/, '')
}

export function addDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() + number));
  return formatDate(someDate)
}

export function reduceDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() - number));
  return formatDate(someDate)
}


export const INITIAL_EVENTS_FULL = [
  {
    id: createEventId(),
    title: '1. Orientation',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Get started with the Web Dev Open curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/71161/lessons/259916`,
    }
  },
  {
    id: createEventId(),
    title: '2. JavaScript Refresher',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Complete until Lesson 10 "Conditionals"',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/263067`,
    }
  },
  {
    id: createEventId(),
    title: '3. Basic Algorithms',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "The goal of this exercise is to get you familiarized with the primitive data structures in JavaScript, which we just covered in the class. Feel free to reference lesson materials and don't limit yourself but be curious and use Google to explore multiple solutions.",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259925`,
    }
  },
  {
    id: createEventId(),
    title: '4. JavaScript Refresher',
    start: addDays(todayStr, 1),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Complete Lesson 12 to 15 until "Arrays & Objects"',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259927`,
    }
  },
  {
    id: createEventId(),
    title: '5. Functions & Arrays',
    start: addDays(todayStr, 1),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "Manipulating arrays in code is a very common operation. Whether you are creating a total for a shopping cart, grabbing only the first names out of a list of people, or moving a piece on a chessboard, you are probably going to be modifying or manipulating an array in some way.",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259931`,
    }
  },
  {
    id: createEventId(),
    title: '6. JS Clue',
    start: addDays(todayStr, 1),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Do you remember the classic detective board game Clue? It was a very popular game in the 90's.

      The plot starts with the death of Mr. Boddy, the very wealthy owner of Tudor Manor. He was killed under highly suspicious circumstances, and it's the player's mission to uncover which suspect murdered him, what weapon was used, and in which room of the mansion the crime took place.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259954`,
    }
  },
  {
    id: createEventId(),
    title: '7 - HTML & CSS',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "Complete the entire 3rd module on HTML and CSS",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259932`,
    }
  },
  {
    id: createEventId(),
    title: '8 - Recipes Clone',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have only learned the very basics today with HTML and CSS, so what the heck we can really build, right? Wrong, we can build some cool stuff already, and we are going to do just that.

      We all have to eat (at least, we should), so let's make some handy recipe pages. We are going to be recreating an Apple Pie recipe. As an extra step, we prepared French Onion Soup recipe as well.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259939`,
    }
  },
  {
    id: createEventId(),
    title: '9 - Spotify Clone',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Everybody likes music, right? Odds are, if you do, you have heard of Spotify.

      In this lab, we will be building a simplified version of the Spotify landing page`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259940`,
    }
  },
  {
    id: createEventId(),
    title: '10 - Flexbox Slack',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You should be reaaaaally familiar with Slack , but just to make it more clear, Slack is a cloud-based set of team collaboration tools and services or in simpler terms, it is a chatting program.

      We use the app almost every day to keep our communications with our classmates so we know perfectly the chat interface but today we are going to clone their landing page. Here you can visit the website and check what are we going to do.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/262710`,
    }
  },
  {
    id: createEventId(),
    title: '11. JS internals',
    start: addDays(todayStr, 3),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete until lesson 31 "Mutable Types"`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259941`,
    }
  },
  {
    id: createEventId(),
    title: '12. Greatest Movies',
    start: addDays(todayStr, 3),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have just learned some useful methods, that will help us manipulate objects and arrays. In this exercise, we will practice working with these methods, and you are required to use at least one of them in each iterat`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259945`,
    }
  },
  {
    id: createEventId(),
    title: '13. Greatest Pokemon',
    start: addDays(todayStr, 3),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `We have just learned some useful methods, that will help us manipulate objects and arrays. In this exercise, we will practice working with these methods, and you are required to use at least one of them in each iterat`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259955`,
    }
  },
  {
    id: createEventId(),
    title: 'REST DAY',
    start: addDays(todayStr, 4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `We're aware of how draining this learning experience is. You might feel quite overwhelmed by the learning. Take a break for what's coming ahead. Rest your eyes, take a walk, talk to your pet, do anything other than looking at your screens`,
      url: `https://youtu.be/dQw4w9WgXcQ?si=QzAIo40ue_mWvaU2`,
    }
  },
  {
    id: createEventId(),
    title: '14. OOP',
    start: addDays(todayStr, 7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 34-38`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259956`,
    }
  },
  {
    id: createEventId(),
    title: '15. JS Vikings',
    start: addDays(todayStr, 7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have learned Object-oriented programming and how class and inheritance work in JavaScript. Now let's work with our Viking friends, applying all of the concepts we have just learned.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259961`,
    }
  },
  {
    id: createEventId(),
    title: '16. DOM',
    start: addDays(todayStr, 8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 40-42`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/259983`,
    }
  },
  {
    id: createEventId(),
    title: '17. JS Cart',
    start: addDays(todayStr, 8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `E-commerce has proven to be a big game-changer in the 21st-century economy. As one of the largest sales channels, second only to physical retail, e-commerce is expected to be responsible for 6.3 trillion USD in worldwide sales by the year 2024.

      E-commerce is a highly competitive business, and building a positive user experience is crucial to retaining customers and improving conversions. It is not uncommon for companies to make a significant investment in optimizing the purchase flow on their e-commerce platforms.
      
      One of the most significant components of this experience is the shopping cart.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262711`,
    }
  },
  {
    id: createEventId(),
    title: '17. Pizza Builder',
    start: addDays(todayStr, 8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `We have got the munchies for a nice, fresh pie of pizza. Of course, we want to order online. After all, talking to a person will only delay the consumption of pizza.

      There is only one problem: our local pizzeria's pizza builder is not working. This time, the local pizzeria is in luck because their customer today is a Web developer. We always hear about how developers should give back to their community. I can't think of a more meaningful contribution than helping the community get pizza more easily.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262712`,
    }
  },
  {
    id: createEventId(),
    title: '18. Async JS',
    start: addDays(todayStr, 9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 45-47`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262734`,
    }
  },
  {
    id: createEventId(),
    title: '19. Chronometer',
    start: addDays(todayStr, 9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this lab, we are going to create a chronometer. Chronometers are very commonly used in sports - car racing, athletics, etc. Why wouldn't we practice a bit of our JS and DOM manipulation knowledge and create our own Chronometer? And then, we can use it to see how many minutes and seconds will take us to complete any of our labs. Sounds like a plan.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262717`,
    }
  },
  {
    id: createEventId(),
    title: '20. Bootstrap',
    start: addDays(todayStr, 10),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 49-50. Learn the basiscs of Bootstrap and how it can be used to quickly create components and style sites`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262714`,
    }
  },
  {
    id: createEventId(),
    title: '21. Cloning Revera',
    start: addDays(todayStr, 10),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Internet provides lots of templates and themes. But some of them cost money. However, we don't need to pay for something we already know how to do, so... We are cloning a theme!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262716`,
    }
  },
  {
    id: createEventId(),
    title: '22. Canvas',
    start: addDays(todayStr, 11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 52-54`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262718`,
    }
  },
  {
    id: createEventId(),
    title: '23. Race Car',
    start: addDays(todayStr, 11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We are ready to start making some games with canvas help, so in this first exercise, we will a Car Race using some basic animations we learned today. This lab is a less informative in the Readme than the other labs. We want you to try your best as to what you've learnt the past couple weeks.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262721`,
    }
  },
  {
    id: createEventId(),
    title: '24. Canvas Advanced',
    start: addDays(todayStr, 14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 56-57`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262723`,
    }
  },
  {
    id: createEventId(),
    title: '25. Hangman',
    start: addDays(todayStr, 14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Complete lesson 56-57`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262725`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 1 🔥',
    start: addDays(todayStr, 15),
    end: addDays(todayStr, 19),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this first project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78756/lessons/262726`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 1 🔥',
    start: addDays(todayStr, 21),
    end: addDays(todayStr, 23),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this first project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78756/lessons/262726`,
    }
  },
  {
    id: createEventId(),
    title: '26. Async Web Apps',
    start: addDays(todayStr, 23),
    end: addDays(todayStr, 26),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 66-72`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79793/lessons/262733`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 2 🔥',
    start: addDays(todayStr, 28),
    end: addDays(todayStr, 33),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this second project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79794/lessons/262742`,
    }
  },
  {
    id: createEventId(),
    title: '27. React Basics',
    start: addDays(todayStr, 35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 80-81`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262753`,
    }
  },
  {
    id: createEventId(),
    title: '28. Hello React',
    start: addDays(todayStr, 35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We just learned the basics about creating a Hello World app in React, so we will use this exercise to re-do what we just learned (and hopefully to add some style 🌼 ).`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262758`,
    }
  },
  {
    id: createEventId(),
    title: '29. React Continue',
    start: addDays(todayStr, 36),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 83-87`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262755`,
    }
  },
  {
    id: createEventId(),
    title: '30. Bulma',
    start: addDays(todayStr, 36),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Do you know Bulma, a very nice alternative to Bootstrap as a CSS framework? We are going to create a simple website with Bulma and React!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262761`,
    }
  },
  {
    id: createEventId(),
    title: '31. React Hooks',
    start: addDays(todayStr, 37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 89-92`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262764`,
    }
  },
  {
    id: createEventId(),
    title: '32. Training',
    start: addDays(todayStr, 37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The purpose of this exercise is to practice the core React concepts such as: creating components, passing data through props, working with state, rendering lists, and working with forms (controlled components).`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262768`,
    }
  },
  {
    id: createEventId(),
    title: '33. Contacts',
    start: addDays(todayStr, 38),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `After Open Bootcamp, you have decided to work in the movie industry, and you've found a job where you need to manage the contacts of a famous producer.

      Your task is to create a contact management app for the producer using React.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262770`,
    }
  },
  {
    id: createEventId(),
    title: '34. Tweets',
    start: addDays(todayStr, 38),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Passing data through props is an important React concept that is best understood by hands-on practice. We'll use this exercise to help you solidify your understanding of props.

      We will be cloning an existing piece of UI from a popular app, Twitter. Let's get started!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/271900`,
    }
  },
  {
    id: createEventId(),
    title: '35. Thinking in react',
    start: addDays(todayStr, 39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 96`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262769`,
    }
  },
  {
    id: createEventId(),
    title: '36. Assignment',
    start: addDays(todayStr, 39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `By now you probably know that in React everything is a component. A React app is built out of components, usually a lot of them. When used, components are usually nested inside of other components.

      To refresh our memory, a component is a reusable piece of code, which defines how certain features should look and behave in the UI.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262785`,
    }
  },
  {
    id: createEventId(),
    title: '37. React Intermediate',
    start: addDays(todayStr, 42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 98-99`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262778`,
    }
  },
  {
    id: createEventId(),
    title: '38. Nutrition',
    start: addDays(todayStr, 42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You just realized that since the beginning of the bootcamp, your diet is not healthy and it may have an impact on your health (and productivity), now and in the long term.

      To take care of the food you eat, you decided to create a nutrition app that will track everything you eat!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262779`,
    }
  },
  {
    id: createEventId(),
    title: '39. Hooks & Routing',
    start: addDays(todayStr, 43),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 101-104`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262788`,
    }
  },
  {
    id: createEventId(),
    title: '40. Wiki Countries',
    start: addDays(todayStr, 43),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `After spending too much time on GitHub, you found a JSON dataset of countries, and you decided to use it to create your Wikipedia of countries!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262787`,
    }
  },
  {
    id: createEventId(),
    title: '41. Context',
    start: addDays(todayStr, 44),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 106`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262790`,
    }
  },
  {
    id: createEventId(),
    title: 'REST DAY',
    start: addDays(todayStr, 45),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `We're aware of how draining this learning experience is. You might feel quite overwhelmed by the learning. Take a break for what's coming ahead. Rest your eyes, take a walk, talk to your pet, do anything other than looking at your screens`,
      url: `https://youtu.be/dQw4w9WgXcQ?si=QzAIo40ue_mWvaU2`,
    }
  },
  {
    id: createEventId(),
    title: 'REST DAY',
    start: addDays(todayStr, 46),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `We're aware of how draining this learning experience is. You might feel quite overwhelmed by the learning. Take a break for what's coming ahead. Rest your eyes, take a walk, talk to your pet, do anything other than looking at your screens`,
      url: `https://youtu.be/dQw4w9WgXcQ?si=QzAIo40ue_mWvaU2`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 3 🔥',
    start: addDays(todayStr, 49),
    end: addDays(todayStr, 54),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this third project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79836/lessons/263303`,
    }
  },
  {
    id: createEventId(),
    title: '42. Node Intro',
    start: addDays(todayStr, 56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 108-109`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262791`,
    }
  },
  {
    id: createEventId(),
    title: '43. Assignment',
    start: addDays(todayStr, 56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Today is our first day using Node, so we will practice doing some basic operations. Ready to start?`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262793`,
    }
  },
  {
    id: createEventId(),
    title: '44. Express Intro',
    start: addDays(todayStr, 57),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 111-112, 114`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262794`,
    }
  },
  {
    id: createEventId(),
    title: '45. Assignment',
    start: addDays(todayStr, 57),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Pick a person that you admire and build a website that shares some basic information about them. They can be a writer, a musician, a celebrity, or someone you know personally.

      You should use express to create your app, build the routing logic with the built-in app routing methods, and serve static content for every reque`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262796`,
    }
  },
  {
    id: createEventId(),
    title: '46. MongoDB Intro',
    start: addDays(todayStr, 58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 115-117`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262929`,
    }
  },
  {
    id: createEventId(),
    title: '47. Advanced Querying',
    start: addDays(todayStr, 58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have learned some super useful query operators that will helps us to make much better queries to retrieve the data we need. For this lab, we will be using the Crunchbase database. Please keep reading and work on the following iterations.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262932`,
    }
  },
  {
    id: createEventId(),
    title: '48. Mongoose Intro',
    start: addDays(todayStr, 59),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 119-121, 123`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262933`,
    }
  },
  {
    id: createEventId(),
    title: '49. Recipes',
    start: addDays(todayStr, 59),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We've learned how to use Mongoose to create Schemas and then interact with our MongoDB database. In the following exercise, we will practice how to implement this by creating awesome recipes.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262937`,
    }
  },
  {
    id: createEventId(),
    title: '50. Templating',
    start: addDays(todayStr, 60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 124-125`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262941`,
    }
  },
  {
    id: createEventId(),
    title: '51. Drinks',
    start: addDays(todayStr, 60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Sometimes you would just like to have a very descriptive list of all beers so you could see their type, color, each beer's percentage of alcohol, or which beer is well pared with some food. In this lab, you will create a web app where the user will be able to see a list of drinks, get random suggestions, and read a very descriptive explanation of each beer.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262943`,
    }
  },
  {
    id: createEventId(),
    title: '52. Express CRUD',
    start: addDays(todayStr, 63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 127-132`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262944`,
    }
  },
  {
    id: createEventId(),
    title: '52. Express Drones',
    start: addDays(todayStr, 63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The goal of this exercise is to create a basic web application using Express. You should be able to use basic Mongoose methods to Create, Read, Update, and Delete documents in a collection in the database.

      The app will allow a user to keep track of their drones collection. Users should be able to see a list of their existing drones (which you will seed using previously gained knowledge), add new ones to their collection, update them as well as delete them when you don't use them anymore.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262952`,
    }
  },
  {
    id: createEventId(),
    title: '54. Express Cinema',
    start: addDays(todayStr, 63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The goal of this exercise is to learn how to seed the database on your own and to be able to create a cinema web page, where we will display the list of movies. In addition to that, you will create a detail page, where more details will be shown for each movie individually.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/271949`,
    }
  },
  {
    id: createEventId(),
    title: '55. FullStack APIs',
    start: addDays(todayStr, 64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 136-139`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79838/lessons/262960`,
    }
  },
  {
    id: createEventId(),
    title: '56. FullStack App',
    start: addDays(todayStr, 64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Build your first full-stack app`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79838/lessons/262970`,
    }
  },
  {
    id: createEventId(),
    title: '57. Express Spotify',
    start: addDays(todayStr, 64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Spotify is a super cool music streaming service that provides you access to tons of music without ever having to buy an album.

      Today, we're going to build an Express app to search Spotify for artists, albums, and tracks. Also, we'll be able to play a preview of some of these songs.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/271950`,
    }
  },
  {
    id: createEventId(),
    title: '58. JWT Auth',
    start: addDays(todayStr, 65),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 142-144`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262972`,
    }
  },
  {
    id: createEventId(),
    title: '58. JWT Auth',
    start: addDays(todayStr, 66),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 145-148`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262975`,
    }
  },
  {
    id: createEventId(),
    title: 'REST DAY',
    start: addDays(todayStr, 67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `We're aware of how draining this learning experience is. You might feel quite overwhelmed by the learning. Take a break for what's coming ahead. Rest your eyes, take a walk, talk to your pet, do anything other than looking at your screens`,
      url: `https://youtu.be/dQw4w9WgXcQ?si=QzAIo40ue_mWvaU2`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 4 🔥',
    start: addDays(todayStr, 70),
    end: addDays(todayStr, 75),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 4 🔥',
    start: addDays(todayStr, 77),
    end: addDays(todayStr, 81),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  },
  {
    id: createEventId(),
    title: 'Graduate ❤️',
    start: addDays(todayStr, 81),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  }
]


export const INITIAL_EVENTS_PART = [
  {
    id: createEventId(),
    title: '1. Orientation',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Get started with the Web Dev Open curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/71161/lessons/259916`,
    }
  },
  {
    id: createEventId(),
    title: '2. JavaScript Refresher',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Complete until Lesson 10 "Conditionals"',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/263067`,
    }
  },
  {
    id: createEventId(),
    title: '3. Basic Algorithms',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "The goal of this exercise is to get you familiarized with the primitive data structures in JavaScript, which we just covered in the class. Feel free to reference lesson materials and don't limit yourself but be curious and use Google to explore multiple solutions.",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259925`,
    }
  },
  {
    id: createEventId(),
    title: '1. JavaScript Refresher',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Complete Lesson 12 to 15 until "Arrays & Objects"',
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259927`,
    }
  },
  {
    id: createEventId(),
    title: '2. Functions & Arrays',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "Manipulating arrays in code is a very common operation. Whether you are creating a total for a shopping cart, grabbing only the first names out of a list of people, or moving a piece on a chessboard, you are probably going to be modifying or manipulating an array in some way.",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259931`,
    }
  },
  {
    id: createEventId(),
    title: '6. JS Clue',
    start: addDays(todayStr, 2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Do you remember the classic detective board game Clue? It was a very popular game in the 90's.

      The plot starts with the death of Mr. Boddy, the very wealthy owner of Tudor Manor. He was killed under highly suspicious circumstances, and it's the player's mission to uncover which suspect murdered him, what weapon was used, and in which room of the mansion the crime took place.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78751/lessons/259954`,
    }
  },
  {
    id: createEventId(),
    title: '1. HTML & CSS',
    start: addDays(todayStr, 4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "Complete the entire 3rd module on HTML and CSS",
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259932`,
    }
  },
  {
    id: createEventId(),
    title: '2. Recipes Clone',
    start: addDays(todayStr, 4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have only learned the very basics today with HTML and CSS, so what the heck we can really build, right? Wrong, we can build some cool stuff already, and we are going to do just that.

      We all have to eat (at least, we should), so let's make some handy recipe pages. We are going to be recreating an Apple Pie recipe. As an extra step, we prepared French Onion Soup recipe as well.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259939`,
    }
  },
  {
    id: createEventId(),
    title: 'Spotify Clone',
    start: addDays(todayStr, 4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Everybody likes music, right? Odds are, if you do, you have heard of Spotify.

      In this lab, we will be building a simplified version of the Spotify landing page`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/259940`,
    }
  },
  {
    id: createEventId(),
    title: '3. Flexbox Slack',
    start: addDays(todayStr, 4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You should be reaaaaally familiar with Slack , but just to make it more clear, Slack is a cloud-based set of team collaboration tools and services or in simpler terms, it is a chatting program.

      We use the app almost every day to keep our communications with our classmates so we know perfectly the chat interface but today we are going to clone their landing page. Here you can visit the website and check what are we going to do.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78752/lessons/262710`,
    }
  },
  {
    id: createEventId(),
    title: 'JS internals',
    start: addDays(todayStr, 7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete until lesson 31 "Mutable Types"`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259941`,
    }
  },
  {
    id: createEventId(),
    title: 'Greatest Movies',
    start: addDays(todayStr, 9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have just learned some useful methods, that will help us manipulate objects and arrays. In this exercise, we will practice working with these methods, and you are required to use at least one of them in each iterat`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259945`,
    }
  },
  {
    id: createEventId(),
    title: 'Greatest Pokemon',
    start: addDays(todayStr, 9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `We have just learned some useful methods, that will help us manipulate objects and arrays. In this exercise, we will practice working with these methods, and you are required to use at least one of them in each iterat`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259955`,
    }
  },
  {
    id: createEventId(),
    title: 'OOP',
    start: addDays(todayStr, 11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 34-38`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259956`,
    }
  },
  {
    id: createEventId(),
    title: 'JS Vikings',
    start: addDays(todayStr, 14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have learned Object-oriented programming and how class and inheritance work in JavaScript. Now let's work with our Viking friends, applying all of the concepts we have just learned.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78753/lessons/259961`,
    }
  },
  {
    id: createEventId(),
    title: 'DOM',
    start: addDays(todayStr, 16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 40-42`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/259983`,
    }
  },
  {
    id: createEventId(),
    title: 'JS Cart',
    start: addDays(todayStr, 18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `E-commerce has proven to be a big game-changer in the 21st-century economy. As one of the largest sales channels, second only to physical retail, e-commerce is expected to be responsible for 6.3 trillion USD in worldwide sales by the year 2024.

      E-commerce is a highly competitive business, and building a positive user experience is crucial to retaining customers and improving conversions. It is not uncommon for companies to make a significant investment in optimizing the purchase flow on their e-commerce platforms.
      
      One of the most significant components of this experience is the shopping cart.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262711`,
    }
  },
  {
    id: createEventId(),
    title: 'Pizza Builder',
    start: addDays(todayStr, 18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `We have got the munchies for a nice, fresh pie of pizza. Of course, we want to order online. After all, talking to a person will only delay the consumption of pizza.

      There is only one problem: our local pizzeria's pizza builder is not working. This time, the local pizzeria is in luck because their customer today is a Web developer. We always hear about how developers should give back to their community. I can't think of a more meaningful contribution than helping the community get pizza more easily.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262712`,
    }
  },
  {
    id: createEventId(),
    title: 'Async JS',
    start: addDays(todayStr, 21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 45-47`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262734`,
    }
  },
  {
    id: createEventId(),
    title: 'Chronometer',
    start: addDays(todayStr, 23),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this lab, we are going to create a chronometer. Chronometers are very commonly used in sports - car racing, athletics, etc. Why wouldn't we practice a bit of our JS and DOM manipulation knowledge and create our own Chronometer? And then, we can use it to see how many minutes and seconds will take us to complete any of our labs. Sounds like a plan.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262717`,
    }
  },
  {
    id: createEventId(),
    title: '1. Bootstrap',
    start: addDays(todayStr, 25),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 49-50. Learn the basiscs of Bootstrap and how it can be used to quickly create components and style sites`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262714`,
    }
  },
  {
    id: createEventId(),
    title: 'Cloning Revera',
    start: addDays(todayStr, 25),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Internet provides lots of templates and themes. But some of them cost money. However, we don't need to pay for something we already know how to do, so... We are cloning a theme!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78754/lessons/262716`,
    }
  },
  {
    id: createEventId(),
    title: '1. Canvas',
    start: addDays(todayStr, 28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 52-54`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262718`,
    }
  },
  {
    id: createEventId(),
    title: 'Race Car',
    start: addDays(todayStr, 28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We are ready to start making some games with canvas help, so in this first exercise, we will a Car Race using some basic animations we learned today. This lab is a less informative in the Readme than the other labs. We want you to try your best as to what you've learnt the past couple weeks.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262721`,
    }
  },
  {
    id: createEventId(),
    title: 'Canvas Advanced',
    start: addDays(todayStr, 30),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 56-57`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262723`,
    }
  },
  {
    id: createEventId(),
    title: 'Hangman',
    start: addDays(todayStr, 32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Complete lesson 56-57`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78755/lessons/262725`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 1 🔥',
    start: addDays(todayStr, 35),
    end: addDays(todayStr, 40),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this first project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78756/lessons/262726`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 1 🔥',
    start: addDays(todayStr, 42),
    end: addDays(todayStr, 47),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this first project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/78756/lessons/262726`,
    }
  },
  {
    id: createEventId(),
    title: 'Async Web Apps',
    start: addDays(todayStr, 49),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 66-72`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79793/lessons/262733`,
    }
  },
  {
    id: createEventId(),
    title: 'Async Web Apps',
    start: addDays(todayStr, 51),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 66-72`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79793/lessons/262733`,
    }
  },
  {
    id: createEventId(),
    title: 'Async Web Apps',
    start: addDays(todayStr, 53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 66-72`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79793/lessons/262733`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 2 🔥',
    start: addDays(todayStr, 56),
    end: addDays(todayStr, 61),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this second project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79794/lessons/262742`,
    }
  },
  {
    id: createEventId(),
    title: 'REST Week',
    start: addDays(todayStr, 63),
    end: addDays(todayStr, 68),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `We're aware of how draining this learning experience is. You might feel quite overwhelmed by the learning. Take a break for what's coming ahead. Rest your eyes, take a walk, talk to your pet, do anything other than looking at your screens`,
      url: `https://youtu.be/dQw4w9WgXcQ?si=QzAIo40ue_mWvaU2`,
    }
  },
  {
    id: createEventId(),
    title: 'React Basics',
    start: addDays(todayStr, 70),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 80-81`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262753`,
    }
  },
  {
    id: createEventId(),
    title: 'Hello React',
    start: addDays(todayStr, 72),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We just learned the basics about creating a Hello World app in React, so we will use this exercise to re-do what we just learned (and hopefully to add some style 🌼 ).`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262758`,
    }
  },
  {
    id: createEventId(),
    title: 'React Continue',
    start: addDays(todayStr, 74),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 83-87`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262755`,
    }
  },
  {
    id: createEventId(),
    title: 'React Continue',
    start: addDays(todayStr, 77),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 83-87`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262755`,
    }
  },
  {
    id: createEventId(),
    title: 'React Continue',
    start: addDays(todayStr, 79),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 83-87`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262755`,
    }
  },
  {
    id: createEventId(),
    title: 'Bulma',
    start: addDays(todayStr, 81),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Do you know Bulma, a very nice alternative to Bootstrap as a CSS framework? We are going to create a simple website with Bulma and React!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262761`,
    }
  },
  {
    id: createEventId(),
    title: 'React Hooks',
    start: addDays(todayStr, 84),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lessons 89-92`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262764`,
    }
  },
  {
    id: createEventId(),
    title: '32. Training',
    start: addDays(todayStr, 86),
    end:  addDays(todayStr, 89),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The purpose of this exercise is to practice the core React concepts such as: creating components, passing data through props, working with state, rendering lists, and working with forms (controlled components).`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262768`,
    }
  },
  {
    id: createEventId(),
    title: 'Contacts',
    start: addDays(todayStr, 91),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `After Open Bootcamp, you have decided to work in the movie industry, and you've found a job where you need to manage the contacts of a famous producer.

      Your task is to create a contact management app for the producer using React.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/262770`,
    }
  },
  {
    id: createEventId(),
    title: 'Tweets',
    start: addDays(todayStr, 91),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Passing data through props is an important React concept that is best understood by hands-on practice. We'll use this exercise to help you solidify your understanding of props.

      We will be cloning an existing piece of UI from a popular app, Twitter. Let's get started!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79795/lessons/271900`,
    }
  },
  {
    id: createEventId(),
    title: '1. Thinking in react',
    start: addDays(todayStr, 93),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 96`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262769`,
    }
  },
  {
    id: createEventId(),
    title: 'Assignment',
    start: addDays(todayStr, 93),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `By now you probably know that in React everything is a component. A React app is built out of components, usually a lot of them. When used, components are usually nested inside of other components.

      To refresh our memory, a component is a reusable piece of code, which defines how certain features should look and behave in the UI.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262785`,
    }
  },
  {
    id: createEventId(),
    title: '1. React Intermediate',
    start: addDays(todayStr, 95),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 98-99`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262778`,
    }
  },
  {
    id: createEventId(),
    title: 'Nutrition',
    start: addDays(todayStr, 95),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You just realized that since the beginning of the bootcamp, your diet is not healthy and it may have an impact on your health (and productivity), now and in the long term.

      To take care of the food you eat, you decided to create a nutrition app that will track everything you eat!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262779`,
    }
  },
  {
    id: createEventId(),
    title: 'Hooks & Routing',
    start: addDays(todayStr, 98),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 101-104`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262788`,
    }
  },
  {
    id: createEventId(),
    title: 'Wiki Countries',
    start: addDays(todayStr, 100),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `After spending too much time on GitHub, you found a JSON dataset of countries, and you decided to use it to create your Wikipedia of countries!`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262787`,
    }
  },
  {
    id: createEventId(),
    title: 'Context',
    start: addDays(todayStr, 102),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 106`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79796/lessons/262790`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 3 🔥',
    start: addDays(todayStr, 105),
    end: addDays(todayStr, 110),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this third project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79836/lessons/263303`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 3 🔥',
    start: addDays(todayStr, 112),
    end: addDays(todayStr, 117),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this third project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79836/lessons/263303`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 3 🔥',
    start: addDays(todayStr, 119),
    end: addDays(todayStr, 123),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this third project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79836/lessons/263303`,
    }
  },
  {
    id: createEventId(),
    title: '42. Node Intro',
    start: addDays(todayStr, 123),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 108-109`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262791`,
    }
  },
  {
    id: createEventId(),
    title: 'Assignment',
    start: addDays(todayStr, 126),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Today is our first day using Node, so we will practice doing some basic operations. Ready to start?`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262793`,
    }
  },
  {
    id: createEventId(),
    title: 'Express Intro',
    start: addDays(todayStr, 128),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 111-112, 114`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262794`,
    }
  },
  {
    id: createEventId(),
    title: 'Assignment',
    start: addDays(todayStr, 130),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Pick a person that you admire and build a website that shares some basic information about them. They can be a writer, a musician, a celebrity, or someone you know personally.

      You should use express to create your app, build the routing logic with the built-in app routing methods, and serve static content for every reque`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79797/lessons/262796`,
    }
  },
  {
    id: createEventId(),
    title: 'MongoDB Intro',
    start: addDays(todayStr, 133),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 115-117`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262929`,
    }
  },
  {
    id: createEventId(),
    title: 'Advanced Querying',
    start: addDays(todayStr, 135),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We have learned some super useful query operators that will helps us to make much better queries to retrieve the data we need. For this lab, we will be using the Crunchbase database. Please keep reading and work on the following iterations.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262932`,
    }
  },
  {
    id: createEventId(),
    title: 'Mongoose Intro',
    start: addDays(todayStr, 137),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 119-121, 123`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262933`,
    }
  },
  {
    id: createEventId(),
    title: 'Recipes',
    start: addDays(todayStr, 140),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `We've learned how to use Mongoose to create Schemas and then interact with our MongoDB database. In the following exercise, we will practice how to implement this by creating awesome recipes.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79835/lessons/262937`,
    }
  },
  {
    id: createEventId(),
    title: 'Templating',
    start: addDays(todayStr, 142),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 124-125`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262941`,
    }
  },
  {
    id: createEventId(),
    title: 'Drinks',
    start: addDays(todayStr, 144),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Sometimes you would just like to have a very descriptive list of all beers so you could see their type, color, each beer's percentage of alcohol, or which beer is well pared with some food. In this lab, you will create a web app where the user will be able to see a list of drinks, get random suggestions, and read a very descriptive explanation of each beer.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262943`,
    }
  },
  {
    id: createEventId(),
    title: 'Express CRUD',
    start: addDays(todayStr, 147),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 127-132`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262944`,
    }
  },
  {
    id: createEventId(),
    title: 'Express CRUD',
    start: addDays(todayStr, 149),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 127-132`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262944`,
    }
  },
  {
    id: createEventId(),
    title: 'Express Drones',
    start: addDays(todayStr, 151),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The goal of this exercise is to create a basic web application using Express. You should be able to use basic Mongoose methods to Create, Read, Update, and Delete documents in a collection in the database.

      The app will allow a user to keep track of their drones collection. Users should be able to see a list of their existing drones (which you will seed using previously gained knowledge), add new ones to their collection, update them as well as delete them when you don't use them anymore.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/262952`,
    }
  },
  {
    id: createEventId(),
    title: 'Express Cinema',
    start: addDays(todayStr, 154),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The goal of this exercise is to learn how to seed the database on your own and to be able to create a cinema web page, where we will display the list of movies. In addition to that, you will create a detail page, where more details will be shown for each movie individually.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/271949`,
    }
  },
  {
    id: createEventId(),
    title: 'FullStack APIs',
    start: addDays(todayStr, 156),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 136-139`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79838/lessons/262960`,
    }
  },
  {
    id: createEventId(),
    title: 'FullStack APIs',
    start: addDays(todayStr, 158),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 136-139`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79838/lessons/262960`,
    }
  },
  {
    id: createEventId(),
    title: 'FullStack App',
    start: addDays(todayStr, 161),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Build your first full-stack app`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79838/lessons/262970`,
    }
  },
  {
    id: createEventId(),
    title: 'Express Spotify',
    start: addDays(todayStr, 163),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Spotify is a super cool music streaming service that provides you access to tons of music without ever having to buy an album.

      Today, we're going to build an Express app to search Spotify for artists, albums, and tracks. Also, we'll be able to play a preview of some of these songs.`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79837/lessons/271950`,
    }
  },
  {
    id: createEventId(),
    title: 'JWT Auth',
    start: addDays(todayStr, 165),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 142-144`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262972`,
    }
  },
  {
    id: createEventId(),
    title: 'JWT Auth',
    start: addDays(todayStr, 168),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 142-144`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262972`,
    }
  },
  {
    id: createEventId(),
    title: 'JWT Auth',
    start: addDays(todayStr, 170),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 145-148`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262975`,
    }
  },
  {
    id: createEventId(),
    title: 'JWT Auth',
    start: addDays(todayStr, 172),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Complete lesson 145-148`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79839/lessons/262975`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 4 🔥',
    start: addDays(todayStr, 175),
    end: addDays(todayStr, 180),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 4 🔥',
    start: addDays(todayStr, 182),
    end: addDays(todayStr, 187),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  },
  {
    id: createEventId(),
    title: 'Project 4 🔥',
    start: addDays(todayStr, 189),
    end: addDays(todayStr, 193),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass done',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  },
  {
    id: createEventId(),
    title: 'Graduate ❤️',
    start: addDays(todayStr, 193),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass',
    extendedProps: {
      desc: `Take your time to complete this fourth project. Remember it's mandatory to complete projects to receive a certificate or a recommendation at any of our partner companies for internships/jobs`,
      url: `https://dashboard.webdevopen.com/c/software-development-a796a2/sections/79840/lessons/263302`,
    }
  }
]